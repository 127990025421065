import React, { useCallback } from 'react';
import { provideLayoutAndData } from '../Page';
import { Link, Redirect } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { PaperResults } from '../ParticipantsResults';
import { ParticipantPastTournamentsPageQuery } from '../GraphQL/types/ParticipantPastTournamentsPageQuery';

const participantPastTournamentsPageQuery = gql`
    query ParticipantPastTournamentsPageQuery {
        viewer {
            _id
            email
            participants {
                _id
                name
                surname
                email
                school
                townName
                grade
                phone
                award
                tournament {
                    ...participantPastTournamentData
                }
                papers {
                    _id
                    stage
                    season
                    level
                    tier
                    type
                    file
                    card
                    venue {
                        _id
                        townName
                    }
                    sum
                    result
                    published
                    marks {
                        mark
                        points
                    }
                }
                onlineTestPaper {
                    _id
                    pages {
                        _id
                        file
                        fileName
                    }
                }
            }
        }
        currentTournament {
            ...participantPastTournamentData
        }
    }

    fragment participantPastTournamentData on Tournament {
        _id
        number
        stage
        seasons {
            fall {
                ...participantPastSeasonData
            }
            spring {
                ...participantPastSeasonData
            }
        }
    }

    fragment participantPastSeasonData on TournamentSeason {
        ordinary {
            date
            onlineStart
            onlineDeadline
        }
        advanced {
            date
            onlineStart
            onlineDeadline
        }
        problemsPublicLink
        criteriaPublicLink
    }
`;

export default provideLayoutAndData<ParticipantPastTournamentsPageQuery>(
    participantPastTournamentsPageQuery,
    {
        authenticatedOnly: true,
    }
)(function ParticipantPastTournamentsPage({ data, viewer }) {
    const { t } = useTranslation();

    if (!viewer.verified) {
        return <Redirect to="/" />;
    }

    const { currentTournament } = data;

    const currentTournamentParticipant =
        data.viewer.participants
            .filter(
                participant =>
                    participant.tournament._id === currentTournament._id
            )
            ?.pop() || null;

    const previousTournamentsParticipants = data.viewer.participants
        .filter(
            participant => participant._id !== currentTournamentParticipant?._id
        )
        .sort((a, b) => b.tournament.number - a.tournament.number);

    return (
        <>
            <CurrentTournamentPastSeasonParticipationInformation
                tournament={currentTournament}
                participant={currentTournamentParticipant}
            />
            <h2>{t('ParticipantHomePage.PastTournaments')}</h2>
            {previousTournamentsParticipants.length === 0 && (
                <p>{t('ParticipantHomePage.NoInfoForPastTournaments')}</p>
            )}
            {previousTournamentsParticipants.map(participant => (
                <PastTournamentParticipationInformation
                    key={participant._id}
                    tournament={participant.tournament}
                    participant={participant}
                />
            ))}
        </>
    );
});

function CurrentTournamentPastSeasonParticipationInformation({
    tournament,
    participant,
}) {
    const { t } = useTranslation();

    if (!tournament || !participant) {
        return null;
    }

    if (
        tournament.stage !== 'FINISHED' &&
        !tournament.stage.startsWith('SPRING')
    ) {
        return null;
    }

    return (
        <>
            <h3>
                {t('TournamentTitle', {
                    number: tournament.number,
                })}
            </h3>
            <PastSeasonParticipationInformation
                tournament={tournament}
                participant={participant}
                season="FALL"
            />
            <ParticipationAwardInformation
                tournament={tournament}
                participant={participant}
            />
            <br />
            <br />
            <br />
        </>
    );
}

function PastTournamentParticipationInformation({ tournament, participant }) {
    const { t } = useTranslation();

    return (
        <>
            <h3>
                {t('TournamentTitle', {
                    number: tournament.number,
                })}
            </h3>
            <PastSeasonParticipationInformation
                tournament={tournament}
                participant={participant}
                season="FALL"
            />
            <br />
            <br />
            <PastSeasonParticipationInformation
                tournament={tournament}
                participant={participant}
                season="SPRING"
            />
            <ParticipationAwardInformation
                tournament={tournament}
                participant={participant}
            />
            <br />
            <br />
            <br />
        </>
    );
}

export function ParticipationAwardInformation({ tournament, participant }) {
    const { t } = useTranslation();

    if (
        participant?.papers.some(paper => paper.stage === 'PUBLISHED') &&
        participant?.award
    ) {
        return (
            <>
                <br />
                <br />
                <h5>
                    {t('ParticipantHomePage.YouAreAwardedWith', {
                        award: participant.award,
                        number: tournament.number,
                    })}
                </h5>
                <p>
                    {t('ParticipantHomePage.BestThreeSolutionsAreConsidered')}
                </p>
                {
                    <p>
                        <DownloadParticipantAwardButton
                            participantId={participant._id}
                            awardType={participant.award}
                        />
                        <br />
                        <br />
                        {tournament.stage === 'FINISHED' ? (
                            <Trans i18nKey="ParticipantPastResults.DigitalAwardNoteFinishedTournament" />
                        ) : (
                            <Trans i18nKey="ParticipantPastResults.DigitalAwardNote" />
                        )}
                    </p>
                }
            </>
        );
    }

    return null;
}

export function PastSeasonParticipationInformation({
    tournament,
    participant,
    season,
}) {
    const { t } = useTranslation();
    const seasonData = tournament.seasons[season.toLowerCase()];
    const { problemsPublicLink } = seasonData;

    if (!participant?.papers.find(paper => paper.season === season)) {
        return (
            <>
                <h4>
                    <HeldSeasonTimeframe
                        tournamentNumber={tournament.number}
                        season={season}
                        ordinaryDate={seasonData.ordinary.date}
                        advancedDate={seasonData.advanced.date}
                    />
                </h4>
                {problemsPublicLink && (
                    <p>
                        <Trans i18nKey="ParticipantPastResults.SolutionsAndCriteriaArePublished">
                            Published
                            <a href={problemsPublicLink}>solutions</a>
                        </Trans>
                    </p>
                )}
            </>
        );
    }

    return (
        <>
            <h4>{t('SeasonTitle', { season })}</h4>
            {problemsPublicLink && (
                <p>
                    <Trans i18nKey="ParticipantPastResults.SolutionsAndCriteriaArePublished">
                        Published
                        <a href={problemsPublicLink}>solutions</a>
                    </Trans>
                </p>
            )}
            {participant.papers
                .filter(paper => paper.season === season)
                .map(paper => (
                    <ParticipantPaperResult
                        key={paper._id}
                        paper={paper}
                        tournament={tournament}
                    />
                ))}
        </>
    );
}

function HeldSeasonTimeframe({
    season,
    ordinaryDate,
    advancedDate,
    tournamentNumber,
}) {
    const { t } = useTranslation();
    const ordinaryMonth = new Date(ordinaryDate).getMonth();
    const advancedMonth = new Date(advancedDate).getMonth();
    const months =
        ordinaryMonth === advancedMonth
            ? t('ParticipantHomePage.monthPrepositional', {
                  context: ordinaryMonth,
              })
            : `${t('ParticipantHomePage.monthPrepositional', {
                  context: ordinaryMonth,
              })}–${t('ParticipantHomePage.monthPrepositional', {
                  context: advancedMonth,
              })}`;

    return (
        <>
            {t('ParticipantPastResults.SeasonWasHeld', {
                season,
                months,
                number: tournamentNumber,
                year: tournamentNumber + 1978 + (season === 'SPRING' ? 1 : 0),
            })}
        </>
    );
}

function ParticipantPaperResult({ paper, tournament }) {
    const { t } = useTranslation();

    if (paper.stage === 'PUBLISHED') {
        return (
            <>
                <h5>{t('LevelTitle', { level: paper.level })}</h5>
                <PaperResults
                    paper={paper}
                    tournamentNumber={tournament.number}
                />
                <Link
                    to={`/tournaments/${tournament.number}/papers/${paper._id}`}
                >
                    {t('ParticipantPastResults.ViewPaper')}
                </Link>
                <br />
                <br />
            </>
        );
    }

    if (
        paper.type === 'ONLINE' &&
        ['SUBMITTED', 'UPLOADED', 'MARKED'].includes(paper.stage)
    ) {
        return (
            <>
                <h5>{t('LevelTitle', { level: paper.level })}</h5>
                {t('ParticipantPastResults.ResultsWillBeAvailableAfterMarking')}
                <br />
                <br />
            </>
        );
    }

    if (paper.type === 'OFFLINE') {
        return (
            <>
                <h5>{t('LevelTitle', { level: paper.level })}</h5>
                {t('ParticipantPastResults.ResultsWillBeAvailableAfterMarking')}
                <br />
                {t('ParticipantPastResults.YouCanAskLocalOrgsForResults')}
                <br />
                <br />
            </>
        );
    }

    return null;
}

const exportParticipantAwardMutation = gql`
    mutation ExportParticipantAward($participantId: ID!) {
        exportParticipantAward(participantId: $participantId, showQr: true) {
            __typename
            ... on ExportAwardsSuccess {
                url
            }
        }
    }
`;

function DownloadParticipantAwardButton({ participantId, awardType }) {
    const { t } = useTranslation();
    const [exportParticipantAward] = useMutation(
        exportParticipantAwardMutation
    );

    const download = useCallback(
        () =>
            exportParticipantAward({ variables: { participantId } }).then(
                ({ data }) => {
                    if (
                        data?.exportParticipantAward.__typename ===
                        'ExportAwardsSuccess'
                    ) {
                        window.location.href = `${process.env.REACT_APP_BACKEND_URL}${data.exportParticipantAward.url}`;
                    }
                }
            ),
        [participantId, exportParticipantAward]
    );

    return (
        <Button size="sm" variant="outline-primary" onClick={download}>
            {t('ParticipantPastResults.DownloadDigitalAwardCopy', {
                award: awardType,
            })}
        </Button>
    );
}
