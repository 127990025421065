import { gql, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from './Page';
import { Col, Row, Spinner } from 'react-bootstrap';
import React from 'react';

const venueResultsInputPageQuery = gql`
    query VenueResultsInputPageQuery($venueId: ID!) {
        venue(id: $venueId) {
            _id
            townName
            tournament {
                _id
                number
                stage
            }
        }
    }
`;

export default function VenueResultsInputPage() {
    const { venueId } = useParams<{ venueId: string }>();
    const { data, loading } = useQuery(venueResultsInputPageQuery, {
        variables: { venueId },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        venue: { tournament, ...venue },
    } = data;

    return (
        <Page>
            <h2>Вбивка — {venue.townName}</h2>
            <h4>Осень</h4>
            <SeasonVenueInputLinks
                tournamentNumber={tournament.number}
                venueId={venue._id}
                season="FALL"
            />
            {(tournament.stage === 'FINISHED' ||
                tournament.stage.startsWith('SPRING')) && (
                <>
                    <br />
                    <br />
                    <h4>Весна</h4>
                    <SeasonVenueInputLinks
                        tournamentNumber={tournament.number}
                        venueId={venue._id}
                        season="SPRING"
                    />
                </>
            )}
        </Page>
    );
}

function SeasonVenueInputLinks({ tournamentNumber, venueId, season }) {
    season = season.toLowerCase();

    return (
        <Row>
            <Col md={6}>
                <h5>
                    <Link
                        to={`/tournaments/${tournamentNumber}/venues/${venueId}/results-input/${season}-ordinary/junior`}
                    >
                        Базовый младший
                    </Link>
                </h5>
                {[5, 6, 7, 8, 9].map(grade => (
                    <>
                        <Link
                            key={grade}
                            to={`/tournaments/${tournamentNumber}/venues/${venueId}/results-input/${season}-ordinary/junior/${grade}`}
                        >
                            {grade}-й класс
                        </Link>
                        <br />
                    </>
                ))}
                <br />
                <h5>
                    <Link
                        to={`/tournaments/${tournamentNumber}/venues/${venueId}/results-input/${season}-ordinary/senior`}
                    >
                        Базовый старший
                    </Link>
                </h5>
                {[10, 11].map(grade => (
                    <>
                        <Link
                            key={grade}
                            to={`/tournaments/${tournamentNumber}/venues/${venueId}/results-input/${season}-ordinary/senior/${grade}`}
                        >
                            {grade}-й класс
                        </Link>
                        <br />
                    </>
                ))}
                <br />
            </Col>
            <Col md={6}>
                <h5>
                    <Link
                        to={`/tournaments/${tournamentNumber}/venues/${venueId}/results-input/${season}-advanced/junior`}
                    >
                        Сложный младший
                    </Link>
                </h5>
                {[5, 6, 7, 8, 9].map(grade => (
                    <>
                        <Link
                            key={grade}
                            to={`/tournaments/${tournamentNumber}/venues/${venueId}/results-input/${season}-advanced/junior/${grade}`}
                        >
                            {grade}-й класс
                        </Link>
                        <br />
                    </>
                ))}
                <br />
                <h5>
                    <Link
                        to={`/tournaments/${tournamentNumber}/venues/${venueId}/results-input/${season}-advanced/senior`}
                    >
                        Сложный старший
                    </Link>
                </h5>
                {[10, 11].map(grade => (
                    <>
                        <Link
                            key={grade}
                            to={`/tournaments/${tournamentNumber}/venues/${venueId}/results-input/${season}-advanced/senior/${grade}`}
                        >
                            {grade}-й класс
                        </Link>
                        <br />
                    </>
                ))}
                <br />
            </Col>
        </Row>
    );
}
